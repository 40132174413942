import { spline } from "./utils/spline";
import SimplexNoise from "simplex-noise";

const initBlobs = () => {
  // our <path> element
  const paths = document.querySelectorAll("#blobs path");
  // used to set our custom property values
  const root = document.documentElement;

  let hueNoiseOffset = 0;
  let noiseStep = 0.005;

  const simplex = new SimplexNoise();

  class Blob {
    constructor(numPoints, radius = 115) {
      this.numPoints = numPoints;
      this.radius = radius;
      this.points = this.createPoints();
    }
    createPoints() {
      const points = [];
      // used to equally space each point around the circle
      const angleStep = (Math.PI * 2) / this.numPoints;
      // the radius of the circle
      const rad = this.radius;

      for (let i = 1; i <= this.numPoints; i++) {
        // x & y coordinates of the current point
        const theta = i * angleStep;

        const x = 100 + Math.cos(theta) * rad;
        const y = 100 + Math.sin(theta) * rad;

        // store the point's position
        points.push({
          x: x,
          y: y,
          // we need to keep a reference to the point's original point for when we modulate the values later
          originX: x,
          originY: y,
          // more on this in a moment!
          noiseOffsetX: Math.random() * 1000,
          noiseOffsetY: Math.random() * 1000,
        });
      }

      return points;
    }
  }

  const points1 = new Blob(4, 89);
  const points2 = new Blob(5, 115);
  const points3 = new Blob(6, 129);
  const points4 = new Blob(6, 229);
  const points5 = new Blob(4, 79);
  const points6 = new Blob(4, 69);
  const points7 = new Blob(4, 59);

  const points = [
    points1.points,
    points2.points,
    points3.points,
    points4.points,
    points5.points,
    points6.points,
    points7.points,
  ];
  (function animate() {
    // for every blob
    paths.forEach((path, idx) => {
      path.setAttribute("d", spline(points[idx], 1, true));
      // for every point...
      for (let i = 0; i < points[idx].length; i++) {
        const point = points[idx][i];

        // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
        const nX = noise(point.noiseOffsetX, point.noiseOffsetX);
        const nY = noise(point.noiseOffsetY, point.noiseOffsetY);
        // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
        const x = map(nX, -1, 1, point.originX - 20, point.originX + 20);
        const y = map(nY, -1, 1, point.originY - 20, point.originY + 20);

        // update the point's current coordinates
        point.x = x;
        point.y = y;

        // progress the point's x, y values through "time"
        point.noiseOffsetX += noiseStep;
        point.noiseOffsetY += noiseStep;
      }
    });

    // const hueNoise = noise(hueNoiseOffset, hueNoiseOffset);
    // const hue = map(hueNoise, -1, 1, 0, 360);

    // root.style.setProperty("--startColor", `hsl(${hue}, 100%, 75%)`);
    // root.style.setProperty("--stopColor", `hsl(${hue + 60}, 100%, 75%)`);
    // document.body.style.background = `hsl(${hue + 60}, 75%, 5%)`;

       root.style.setProperty("--startColor", `hsl(${122}, 100%, 75%)`);
    root.style.setProperty("--stopColor", `hsl(${122 + 60}, 100%, 75%)`);
    // document.body.style.background = `hsl(${60}, 75%, 2%)`;

    hueNoiseOffset += noiseStep / 6;

    requestAnimationFrame(animate);
  })();

  function map(n, start1, end1, start2, end2) {
    return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
  }

  function noise(x, y) {
    return simplex.noise2D(x, y);
  }
};

export { initBlobs };
